// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import SmileOutlined from '/opt/buildhome/repo/node_modules/@ant-design/icons/es/icons/SmileOutlined';
import TableOutlined from '/opt/buildhome/repo/node_modules/@ant-design/icons/es/icons/TableOutlined';
import BankOutlined from '/opt/buildhome/repo/node_modules/@ant-design/icons/es/icons/BankOutlined';
import SolutionOutlined from '/opt/buildhome/repo/node_modules/@ant-design/icons/es/icons/SolutionOutlined';
import IdcardOutlined from '/opt/buildhome/repo/node_modules/@ant-design/icons/es/icons/IdcardOutlined';
import ProfileOutlined from '/opt/buildhome/repo/node_modules/@ant-design/icons/es/icons/ProfileOutlined';
import UserOutlined from '/opt/buildhome/repo/node_modules/@ant-design/icons/es/icons/UserOutlined';
import PayCircleOutlined from '/opt/buildhome/repo/node_modules/@ant-design/icons/es/icons/PayCircleOutlined';
import GiftOutlined from '/opt/buildhome/repo/node_modules/@ant-design/icons/es/icons/GiftOutlined';
import AuditOutlined from '/opt/buildhome/repo/node_modules/@ant-design/icons/es/icons/AuditOutlined';
import UploadOutlined from '/opt/buildhome/repo/node_modules/@ant-design/icons/es/icons/UploadOutlined';
import CustomerServiceOutlined from '/opt/buildhome/repo/node_modules/@ant-design/icons/es/icons/CustomerServiceOutlined';
import FormOutlined from '/opt/buildhome/repo/node_modules/@ant-design/icons/es/icons/FormOutlined';
import SettingOutlined from '/opt/buildhome/repo/node_modules/@ant-design/icons/es/icons/SettingOutlined';
export default { SmileOutlined, TableOutlined, BankOutlined, SolutionOutlined, IdcardOutlined, ProfileOutlined, UserOutlined, PayCircleOutlined, GiftOutlined, AuditOutlined, UploadOutlined, CustomerServiceOutlined, FormOutlined, SettingOutlined };
